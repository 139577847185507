import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import {Storage} from "@/Storage";


const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            requiresAuth: false,
            guest: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            guest: true
        }
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import(/* webpackChunkName: "login" */ '../views/Impressum.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: () => import(/* webpackChunkName: "login" */ '../views/Datenschutz.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/settings/:currentTab?/:id?',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Configuration.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/secret',
        name: 'secret',
        component: () => import(/* webpackChunkName: "secret" */ '../views/SecretView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/report/:identifierType/:identifier',
        name: 'report',
        component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cockpit',
        name: 'cockpit',
        component: () => import(/* webpackChunkName: "report" */ '../views/Cockpit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/*',
        component: () => import(/* webpackChunkName: "report" */ '../views/NotFound.vue'),
        meta: {
            requiresAuth: false,
            guest: true
        }
    }
]

const router = createRouter({
    // mode: history,
    history: createWebHistory(process.env.BASE_URL),
    // base: process.env.BASE_URL,
    routes
})

// Redirects an unauthorized user to login, and after successful login back to target url
// to: where the users wants to go, from: where the user comes from, next: callback for continuing the request-processing.
router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log(to);

        if (!Storage.isAuthenticated()) {
            next({
                // use "named" routes to don't ignore url params
                name: 'login',
                params: {nextUrl: to.fullPath}
            })
        } else {
            let user = Storage.getUsername()
            if (to.matched.some(record => record.meta.is_admin)) {
                if (user.is_admin == 1) {
                    next()
                } else {
                    next({name: 'login'})
                }
            } else {
                next()
            }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (!Storage.isAuthenticated()) {
            next()
        } else {
            next({name: 'login'})
        }
    } else {
        next()
    }
})

export default router
