<template>
  <section>
  <div class="dark:header-background">
    <div class="container mx-auto">
      <header>
        <img @click= "goHome()" alt="jung-diagnostics-logo" class="dark:logo-filter" src="/assets/images/jung_radiology_logo.png" style="height: 6rem; padding: 1rem;">
      </header>
    </div>
    <hr class="border-black">
  </div>
  <div class="container mx-auto min-h-80">
    <div class="flex flex-col my-2 mx-2">
      <div id="nav" v-if="loggedIn">
        <router-link to="/">Home</router-link>
        |
        <router-link to="/settings">Einstellungen</router-link>
        <div style="float: right">
          <full-username></full-username>
        </div>
        <div>

        </div>
      </div>
      <router-view/>
    </div>
  </div>

  <toast-manager @removeToast="removeToast" toasts="toasts"></toast-manager>
  </section>

  <footer class="dark:footer-background min-h-footer-height " style="clear: both; margin-top: 1rem;">
    <hr class="py-3 border-black">
    <div class="flex justify-between container mx-auto">
      <span>ReportX - KI-gestütztes Befundungsmodul - Version 0.0.1</span>
      <div>
        <router-link class="px-10 border-black" to="/impressum">Impressum</router-link>
        <router-link class="px-10 border-black" to="/datenschutz">Datenschutz</router-link>
      </div>
      <span class="align-right" > <button v-if= "loggedIn" @click="showContactWindow = true" type="button" class="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500f ">Kontakt</button></span>
    </div>
    <div  v-show="showContactWindow" tabindex="-1" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-hidden="true" id="defaultModal">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" >
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">

              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">JDX kontaktieren</h3>
                <div class="mt-2">
                  <br>
                  <p class="text-sm text-gray-500">Schreiben Sie uns Ihre Anmerkung in dem vorgegebenen Eingabefeld. Damit wir Sie später kontaktieren können, teilen Sie uns bitte Ihre E-Mail-Adresse mit.</p>
                </div>
                <br>
                <label  class="block text-sm font-medium text-gray-700">Anmerkung</label>
                <textarea v-model="annotation" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:bg-gray-700 focus:border-indigo-500 focus:outline-none shadow-sm"
                    id="annotation"
                    rows="3"
                    placeholder="Anmerkung"
                ></textarea>
                <br>
                <label  class="block text-sm font-medium text-gray-700">E-Mail</label>
                <textarea v-model="email" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:bg-gray-700 focus:border-indigo-500 focus:outline-none shadow-sm"
                    id="email"
                    rows="1"
                    placeholder="E-Mail"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button  @click="sendForm(); showContactWindow = false" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">Senden</button>
            <button  @click="showContactWindow = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Abbrechen</button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}

#nav {
  padding: 0.5rem 0 0.5rem 0;
  /*padding: 0.5rem 25px 15px 25px;*/
}

#nav a {
  font-weight: bold;
  /*color: #2c3e50;*/
}

#nav a.router-link-exact-active {
  /*color: #42b983;*/
}
</style>
<script>
import ToastManager from "@/components/ToastManager";
import FullUsername from "@/components/FullUsername";

export default {
  components: {FullUsername, ToastManager},
  data() {
    return {
      annotation: "",
      email: "",
      showContactWindow: false,
      toastId: 0,
      toasts: []
    }
  },
  computed: {


    darkMode() {
      if (this.this.$store.state.user.darkMode === true) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      return this.$store.state.user.darkMode
    },
    loggedIn() {
      return this.$store.state.user.username !== undefined
    }
  },
  methods: {

    goHome(){
      this.$router.push({ name: 'home'});
    },

    username() {
      console.log(this.$store.state.user.username)
      return this.$store.state.user.username !== null;
    },
    sendForm(){
      let data = {
        url: window.location.href,
        email: this.email,
        annotation: this.annotation,
        username: this.username
      }
      this.$http
          .post('/api/v1/contact/', data)
          .then(response => {
            console.log(response)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Senden der Notification ist ein Fehler aufgetreten. Sollte dies weiter bestehen können Sie sich auch direkt an info@jung-diagnostics.de wenden.")
          })
    },
    addToast(title, message, type) {
      this.$store.commit('toast/addToast', title, message, type)
    },
    removeToast() {
      this.toasts.shift()
    },
    setupDarkMode() {
      if (this.darkMode === true) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }
  }
}

</script>