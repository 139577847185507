import axios from 'axios'
import router from './router'
import store from "@/store/store";
import jwt_decode from "jwt-decode";
// import parseJwk from 'jose/dist/browser/jwk/parse'

export class Storage {

    static LOCAL_STORAGE_ACCESS_TOKEN = 'access_token';
    static LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';
    static LOCAL_STORAGE_USERNAME = 'username';
    static LOCAL_STORAGE_ROLE = 'role';
    static LOCAL_STORAGE_DARK_MODE = 'dark-ui-mode';

    static isAuthenticated() {
        return this.getAccessToken() !== null;

    //    if (jwtVerify(Storage.getAccessToken())) {
        //             return true
        //         }
    }

    static getAuthentication() {
        return {
            headers: { 'Authorization': 'Bearer ' + this.getAccessToken() }
        };
    }

    static refreshJwtToken() {
        return new Promise((resolve, reject) => {
            axios
                .post("/oauth/access_token",
                    {
                        grant_type: 'refresh_token',
                        refresh_token: this.getRefreshToken()
                    })
                .then(response => {
                    console.log("refreshed jwt token, updating token data")
                    this.updateData(response.data)
                    console.log(response.data)
                    resolve(response.data.access_token);
                    console.log("refresh finished, retrying last request")
                })
                .catch((error) => {
                    // Refresh failed - Logout
                    this.clearTokens()
                    reject(error);
                    router.push({name: "login"})
                });
        });
    }

    static decodeJwt(accessToken) {
        try {
            return jwt_decode(accessToken)
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    static getValueFromToken(key) {
        let jwt = this.decodeJwt(this.getAccessToken())
        return jwt[key]
    }

    static updateData(data, root) {
        let accessToken = data.access_token;
        let refreshToken = data.refresh_token;
        let username = data.username;

        // let info = this.decodeJwt(accessToken)

        Storage.storeAccessToken(accessToken)
        Storage.storeRefreshToken(refreshToken)
        Storage.storeUsername(username, root)

        let info = Storage.decodeJwt(accessToken)
        if (info !== undefined) {
            console.log(info)
            store.commit('user/setUser', {
                username: info.username,
                name: info.name,
                darkMode: info.darkMode,
                roles: info.roles
            })
        }

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
        // Storage.setDarkMode(info.darkMode, root)
    }

    static clearTokens() {
        localStorage.removeItem(Storage.LOCAL_STORAGE_ACCESS_TOKEN)
        localStorage.removeItem(Storage.LOCAL_STORAGE_REFRESH_TOKEN)
        localStorage.removeItem(Storage.LOCAL_STORAGE_USERNAME)
        // delete axios.$http.defaults.headers.common['Authorization']
        axios.defaults.headers.common['Authorization'] = undefined;
    }

    static storeAccessToken(accessToken) {
        localStorage.setItem(Storage.LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }

    static getAccessToken() {
        return localStorage.getItem(Storage.LOCAL_STORAGE_ACCESS_TOKEN);
    }

    static storeRefreshToken(refreshToken) {
        localStorage.setItem(Storage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    }

    static getRefreshToken() {
        return localStorage.getItem(Storage.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    static storeUsername(username, root) {
        if (root) root.username = username
        localStorage.setItem(Storage.LOCAL_STORAGE_USERNAME, username)
    }

    static getUsername() {
        return localStorage.getItem(Storage.LOCAL_STORAGE_USERNAME);
    }

    static storeRole(role) {
        localStorage.setItem(Storage.LOCAL_STORAGE_ROLE, role)
    }

    static getRole() {
        return localStorage.getItem(Storage.LOCAL_STORAGE_ROLE);
    }

    static setDarkMode(darkModeEnabled, root) {
        if (root) root.darkMode = darkModeEnabled
        localStorage.setItem(Storage.LOCAL_STORAGE_DARK_MODE, darkModeEnabled);
    }

    static getDarkMode() {
        let darkModeEnabled = localStorage.getItem(Storage.LOCAL_STORAGE_DARK_MODE);
        if (darkModeEnabled === "true" || darkModeEnabled === true) return true // check if undefined
        return false
    }

}