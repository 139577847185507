<template>
  <!-- mx-2 -->

  <div v-if="worklist.length > 0" class="my-2 overflow-x-auto">
    <div class="py-2 align-middle inline-block min-w-full">
      <div class="text-left my-2">Worklist</div>
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50 dark:bg-gray-600">
          <tr class="text-gray-500 dark:text-gray-100">
            <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
              Job ID
            </th>
            <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
              Patient Name
            </th>
            <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
              Birth Date<br/>
              (Month.Year)
            </th>
            <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
              Job State
            </th>
          </tr>
          </thead>
          <tbody class="bg-white dark:bg-gray-600 divide-y divide-gray-200">
          <tr class="cursor-pointer hover:bg-gray-300 hover:text-black" v-for="job in worklist" :key="job.id"
              @click="openJob(job)">
            <td class="px-4 py-4 whitespace-nowrap text-left">
              {{ job.id }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-left">
                {{ job.patient.name }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-left">
                {{ getDate(job.patient.birthDate) }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-left">
              {{ job.jobState }}
            </td>
          </tr>
          <tr>
            <td colspan="4" class="px-4 py-1 whitespace-nowrap">
                <div class="flex justify-center items-baseline">
                <span class="px-4 py-1 mb-0 whitespace-nowrap">
                  <input class="mb-0" @click="decreasePage" v-if="page > 0" type="button" value="Zurück"/>
                  <input class="mb-0" @click="decreasePage" v-if="!page > 0" type="button" disabled value="Zurück"/>
                </span>
                    <span class="px-4 py-1 whitespace-nowrap">
                  {{page+1}} / {{totalPages}}
                </span>
                <span class="px-4 py-1 mb-0 whitespace-nowrap">
                  <input class="mb-0" @click="increasePage" v-if="totalPages-1>page" type="button" value="Weiter"/>
                  <input class="mb-0" @click="increasePage" v-if="!(totalPages-1>page)" type="button" disabled value="Weiter"/>
                </span>
                </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import {DateFormatter} from "@/DateFormatter";

export default {
  name: "Worklist",
  data() {
      return {
          page: 0,
          worklist: [],
          totalPages: 0
      }
  },
  methods: {
    openJob(job) {
      this.$router.push({
        name: 'report',
        params: {identifierType: 'jdxJobId', identifier: job.id}
      })
    },
    getDate(d) {
      if (d) {
        try {
          let date = new Date(d);
          return DateFormatter.getMonthYearStringFromDate(date)
        } catch (err) {
          return ""
        }
      }
      return ""
    },
    loadWorkList() {
        this.$http
            .get('api/v1/job/worklist?page=' + this.page)
            .then(response => {
                this.worklist = response.data.content
                this.totalPages = response.data.totalPages
                console.log(response)
            }).catch(reason => {
            console.log(reason)
        });
    },
    increasePage() {
        this.page++
        this.loadWorkList()
    },
    decreasePage() {
        this.page--
        this.loadWorkList()
    }
  },
  mounted() {
    this.loadWorkList()
  }
}
</script>

<style scoped>
  .case {
    cursor: pointer;
  }
</style>