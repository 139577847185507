const toastStore = {
    namespaced: true,
    state: () => ({
        toasts: [],
        toastId: 0
    }),
    mutations: {
        addToast(state, toast) {
            console.log(toast.title, toast.message, toast.type)
            state.toasts.push({
                id: state.toastId++,
                title: toast.title,
                message: toast.message,
                type: toast.type,
                created: new Date(),
                visible: true,
            })
        },
        removeToast(state) {
            state.toasts.shift()
        },
    },
    getters: {
        toasts: state => {
            return state.toasts
        }
    }
}

export default toastStore