import {createStore} from 'vuex'
import userStore from './UserStore'
import toastStore from "@/store/ToastStore";

const store = createStore({
    modules: {
        user : userStore,
        toast: toastStore
    },
    state() {
        return {
            counter: 0
        }
    },
    mutations: {
        increment(state) {
            state.counter++
        }
    }
})

export default store