export class DateFormatter {

    static getMonthYearStringFromDate(date) {
        if (date instanceof Date) {
            let month = date.getMonth() + 1
            let monthString = "" + month
            if (monthString.length === 1) {
                monthString = "0" + monthString
            }
            let year = date.getFullYear()
            return monthString + "." + year
        }
    }

}