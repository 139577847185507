

<template>

  <transition name="slide-fade">
    <div class="toast" v-if="visible">
      <div class="center">
        <div class="title">{{title}}</div> - <div class="message">{{message}}</div>
      </div>
    </div>
  </transition>

</template>

<script>

export default {
  name: "Toast",
  props: ["id", "title", "message", "type"],
  data() {
    return {
      visible: true
    }
  },
  created() {
    setTimeout(() => {
      this.visible = false;
      this.$store.commit('toast/removeToast');
    }, 5000)
  }
}
</script>

<style scoped>

  .toast {
    transition: top 2s ease-in-out 1s;
    min-width: 10rem;
    min-height: 2.5rem;
    margin: 2rem auto;
    padding: .5rem .75rem;
    border: 1px solid #535353;
    background-color: #424242;
    box-shadow: 10px 5px 5px rgba(0,0,0,0.2);
    display: flex;
    color: whitesmoke;
  }

  .title{
    font-weight: bold;
    display: inline;
  }

  .info {
    /*background-color: #e4f2ff;*/
  }

  .warning {
    /*background-color: #ffcfcf;*/
  }

  .message {
    display: inline;
  }


  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(50px);
    opacity: 0;
  }

  .center{
    margin-left: auto;
    margin-right: auto;
  }
</style>