<template>

  <!-- mx-2 -->

    <div class="my-2 overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="text-left my-2">Last Cases</div>
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50 dark:bg-gray-600">
            <tr class="text-gray-500 dark:text-gray-100">
              <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Birth Date <br>
                <span class="text-xs"> (month.year) </span>
              </th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Sex
              </th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Patient ID
              </th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Series UID Ending
              </th>
            </tr>
            </thead>
            <tbody class="bg-white dark:bg-gray-600 divide-y divide-gray-200">
              <tr class="cursor-pointer hover:bg-gray-300 hover:text-black" v-for="aCase in lastCases" :key="aCase.id" @click="openCase(aCase)">
                <td class="px-4 py-4 whitespace-nowrap text-left">
                  {{aCase.patientName}}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-left">
                  {{ getDate(aCase.patientBirthDate) }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-left">
                  {{ aCase.patientSex }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-left">
                  {{ aCase.patientId }}
                </td>
                <td class="px-4 py-4 whitespace-nowrap text-left">
                  <div v-if="aCase.seriesInstanceUid">
                    {{ "..." + aCase.seriesInstanceUid?.slice(-10) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

</template>

<script>
import {DateFormatter} from "@/DateFormatter";

export default {
  name: "History",
  props: {
    lastCases: Array
  },
  methods: {
    openCase(aCase) {
      if (aCase.seriesInstanceUid) {
        this.$router.push({
          name: 'report',
          params: {identifierType: 'seriesInstanceUid', identifier: aCase.seriesInstanceUid}
        })
      } else if (aCase.studyInstanceUid) {
        this.$router.push({
          name: 'report',
          params: {identifierType: 'studyInstanceUid', identifier: aCase.studyInstanceUid}
        })
      } else if (aCase.accessionNumber) {
        this.$router.push({
          name: 'report',
          params: {identifierType: 'accessionNumber', identifier: aCase.accessionNumber}
        })
      } else if (aCase.jdxJobId) { // TODO: if implemented move up in order to prioritize jdxJobID like seriesInstanceUID
        this.$router.push({name: 'report', params: {identifierType: 'jdxJobId', identifier: aCase.jdxJobId}})
      }
    },
    getDate(d) {
      if (d) {
        try {
          let date = new Date(d);
          return DateFormatter.getMonthYearStringFromDate(date)
        } catch (err) {
          return ""
        }
      }
      return ""
    }
  }
}
</script>

<style scoped>
  .case {
    cursor: pointer;
  }
</style>