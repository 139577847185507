import {Storage} from "@/Storage";
import {darkMode} from "../../tailwind.config";

const userStore = {
    namespaced: true,
    state: () => ({
        username: undefined,
        name: undefined,
        darkMode: false,
        roles: []
    }),
    mutations: {
        setUser(state, user) {
            console.log("UserStore.js - setUser(state, user)")
            console.log(state)
            console.log(user)
            console.log("darkmode is: " + darkMode)
            state.username = user.username
            state.name = user.name
            state.roles = user.roles
            document.documentElement.classList.add("dark");
        },
        clearUserDetails(state) {
            state.username = undefined
            state.name = undefined
            Storage.clearTokens()
        }
    },
    getters: {
    }
}

export default userStore