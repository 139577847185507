import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store/store"
import axios from 'axios'
import './assets/tailwind.css'
import {Storage} from "@/Storage";
import Toast from "@/components/Toast";
import VueMultiselect from 'vue-multiselect'
const app = createApp(App).use(router)

app.use(store)

// restore user data, if a valid token is present
initState();

app.component("toast", Toast)
app.component("VueMultiselect", VueMultiselect)

const accessToken = Storage.getAccessToken()

if (process.env.NODE_ENV === "development") {
    let url = new URL(window.location.href)
    url.port = '8082'
    axios.defaults.baseURL = "http://" + url.host + "/";
}

if (accessToken) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
}

axios.interceptors.response.use(function (response) {
    // status: 2xx
    // console.log(response)
    return response;
}, function (error) {

    // status outside of 2xx:
    if (error.response.status !== 401) {
        // Return the error for user information or other kind of handling
        console.log("An error occured while the request. The error handling is left to view. Error Response: ")
        console.log(error.response)
        return new Promise((resolve, reject) => {
            reject(error)
        })
    }

    if (error.config.url === "/oauth/access_token") {
        console.log("An error occured while refreshing the access token. User is logged out. Error Response: ")
        console.log(error.response)
        // error while refreshing token, so log out
        localStorage.removeItem('access-token')
        return new Promise(((resolve, reject) => {
            reject(error)
            return router.push({name: "Login"})
        }))
    }

    console.log("The access token is expired. Trying to refresh the token. The error response is: ")
    console.log(error.response)
    // try to refresh token and repeat failed request
    return Storage.refreshJwtToken().then((token) => {
        console.log("Setting new bearer after token refresh.")
        const config = error.config
        config.headers['Authorization'] = 'Bearer ' + token

        return new Promise(((resolve, reject) => {
            console.log("Repeating last request:")
            console.log(axios.request(config))
            axios.request(config).then(response => {
                console.log("Repeated request successful.")
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        }))
    }).catch((error) => {
        return Promise.reject(error)
    })

})

app.config.globalProperties.$http = axios;
app.mount('#app');

function initState() {
    let accessToken = Storage.getAccessToken()

    if (accessToken) {
        let info = Storage.decodeJwt(accessToken)
        if (info !== undefined) {
            console.log(info?.username, info?.name, info?.darkMode)
            store.commit('user/setUser', {
                username: info.username,
                name: info.name,
                darkMode: info.darkMode,
                roles: info.roles
            })
        } else {
            Storage.clearTokens()
        }
    }
}