

<template>
  <div class="toasts">
    <div v-for="toast in toasts" :key="toast.id">
      <toast :title="toast.title" :message="toast.message" :type="toast.type" :id="toast.id"></toast>
    </div>
  </div>
</template>

<script>
import Toast from "@/components/Toast";
export default {
  name: "ToastManager",
  components: {Toast},
  props: {
  },
  computed: {
    toasts() {
      return this.$store.state.toast.toasts;
    }
  },
  methods: {
  }
}
</script>

<style scoped>

.toasts {
  width: 30rem;
  position: fixed;
  bottom: 1rem;
  left: calc(50% - 15rem);
}
</style>