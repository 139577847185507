<template>
  <span>
<!--    <object data="/assets/images/hericons/solid/user-circle.svg" style="height: 1.5rem; display: inline; line-height: 1.5rem; margin: -2px 0.25rem auto 0.25rem; fill: whitesmoke"/>-->
  </span>
  <span v-if="username">{{username}}</span>
  <span v-if="username !== undefined"> | <span @click="logout" class="link">Logout</span></span>
</template>

<script>
export default {
  name: 'full-username',
  computed: {
    username() {
      return this.$store.state.user.name
    }
  },
  methods: {
    logout() {
      this.$store.commit('user/clearUserDetails');
      this.$router.push("/login");
    }
  }
}
</script>


<style scoped>
  .link{
    cursor: pointer;
  }

  .link:hover{
    color: lightgrey;
  }
</style>
