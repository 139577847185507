<template>
  <div class="home-container">
    <h1>Home</h1>

    <Worklist></Worklist>

    <History :lastCases="lastCases"></History>

    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-1 lg:px-8">
        <div class="my-2 pt-10 text-left">
          Direkter Zugriff
        </div>

        <div class="my-2">
          <form v-on:submit="reportByStudyInstanceUid">
            <label class="mx-2 inline-block w-20 text-left" for="studyInstanceUid">Studie:</label>
            <input class="my-2 w-2/3 dark:dark-input" v-model="studyInstanceUid" type="text" id="studyInstanceUid"
                   name="studyInstanceUid" placeholder="Study Instance UID">
            <input class="my-2" type="submit" value="Report">
          </form>
        </div>

        <div class="my-2">
          <form v-on:submit="reportByAccessionNumber">
            <label class="mx-2 inline-block w-20 text-left" for="accessionNumber">Accession:</label>
            <input class="my-2 w-2/3 dark:dark-input" v-model="accessionNumber" type="text" id="accessionNumber"
                   name="accessionNumber" placeholder="Accession Number">
            <input class="my-2" type="submit" value="Report">
          </form>
        </div>

        <div class="my-2">
          <form v-on:submit="reportBySeriesInstanceUid">
            <label class="mx-2 inline-block w-20 text-left" for="seriesInstanceUid">Serie:</label>
            <input class="my-2 w-2/3 dark:dark-input" v-model="seriesInstanceUid" type="text" id="seriesInstanceUid"
                   name="seriesInstanceUid" placeholder="Series Instance UID">
            <input class="my-2" type="submit" value="Report">
          </form>
        </div>

        <div class="my-2">
          <form v-on:submit="reportByJdxJobId">
            <label class="mx-2 inline-block w-20 text-left" for="jdxJobId">JDX ID:</label>
            <input class="my-2 w-2/3 dark:dark-input" v-model="jdxJobId" type="text" id="jdxJobId" name="jdxJobId"
                   placeholder="JDX Job ID">
            <input class="my-2" type="submit" value="Report">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import History from "@/components/History";
import Worklist from "@/components/Worklist";

export default {
  name: 'home',
  components: {History, Worklist},
  emits: {
    'onToast': null
  },
  // components: {Toast},
  data() {
    return {
      studyInstanceUid: "",
      accessionNumber: "",
      seriesInstanceUid: "",
      jdxJobId: "",
      loading: true,
      lastCases: []
    }
  },
  methods: {
    reportByAccessionNumber(e) {
      e.preventDefault()
      this.callReport("accessionNumber", this.accessionNumber)
    },
    reportByJdxJobId(e) {
      e.preventDefault()
      this.callReport("jdxJobId", this.jdxJobId)
    },
    reportByStudyInstanceUid(e) {
      e.preventDefault()
      this.callReport("studyInstanceUid", this.studyInstanceUid)
    },
    reportBySeriesInstanceUid(e) {
      e.preventDefault()
      this.callReport("seriesInstanceUid", this.seriesInstanceUid)
    },
    callReport(type, identifier) {
      if (identifier.toString().trim() !== "") {
        this.$router.push({
          name: "report",
          params: {
            identifierType: type,
            identifier: identifier
          }
        })
      } else {
        alert("Please provide enter an identifier.")
      }
    }
  },
  mounted() {
    // this.loadingReportData = true;
    console.log("Mounted")
    this.$http
        .get('api/v1/case/last/')
        .then(response => {
          this.lastCases = response.data
          console.log(response)
        }).catch(reason => {
      console.log(reason)
    });
  }
}
</script>

<style>
  .home-container {
    text-align: center;
  }
</style>
