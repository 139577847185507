const colors = require('tailwindcss/colors')

module.exports = {
    purge: {content: ['./public/**/*.html', './src/**/*.vue']},
    darkMode: 'class', // or 'media' or 'class'
    theme: {
        extend: {
            colors: {
                gray: colors.trueGray,
                indigo: colors.indigo,
                red: colors.rose,
                yellow: colors.amber,
            }
        },
        minHeight: {
            '0': '0',
            '1/4': '25vh',
            '1/2': '50vh',
            '3/4': '75vh',
            '80': '80vh',
            'full': '100vh',
            'footer-height': 'calc(100vh - 80vh)'
        }
    },
    variants: {
        extend: {},
    },
    plugins: [],
}
